// ** Logo
import { useTranslation } from 'react-i18next'
import logo from '@src/assets/images/logo/logo_loading.png'

const SpinnerComponent = () => {
  const { t } = useTranslation()
  return (
    <div className='fallback-spinner app-loader'>
      <img className='fallback-logo' src={logo} alt='logo' />
      <h2 className="mt-1 mb-0 text-primary fw-bolder">{t("Free Gifts by Secomapp")}</h2>
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
