// control show data type
// ** Import
import moment from "moment"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: "short", day: "numeric", year: "numeric" }) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")

export const getUserData = () => {
  try {
    return JSON.parse(localStorage.getItem("userData"))
  } catch (e) {
    return {}
  }
}

export const setUserData = (user) => {
  try {
    localStorage.setItem(
      "userData",
      JSON.stringify({
        shop_name: user?.shop_name,
        app_status: user?.app_status,
        shop_owner: user?.shop_owner
      })
    )
  } catch (e) {}
}

export const checkAuthLogin = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const paramsName = ["hmac", "host", "shop", "timestamp"]
  const embedded = parseInt(urlParams.get("embedded"))
  let check = (!urlParams?.has("embedded") || embedded === 0) && !urlParams.has("appLoadId")
  for (const key of paramsName) {
    check &= urlParams.has(key)
  }

  return !check
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/"
  if (userRole === "client") return "/access-control"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

/*
 * @param {Date} date
 * @param {string} format
 */
export const showDate = (date, format) => {
  const dateFormat = format ?? "YYYY-MM-DD"
  const dateCheck = Array.isArray(date) ? date[0] : date
  return date ? moment(dateCheck).format(dateFormat) : "No date"
}

export const convertLinkCustom = (link) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const host = urlParams.get("host")
  return host ? `${link}?host=${host}` : link
}

export const getShopifyDomain = () => {
  const urlParams = new URLSearchParams(location.search)

  const { app } = window
  let shop = urlParams.get("shop")
  try {
    const host = atob(window.__SHOPIFY_DEV_HOST)
    if (host?.includes("admin.shopify.com")) {
      const arrDomains = host.split("/")
      const name = arrDomains.slice(-1)[0]
      shop = shop ?? `${name}.myshopify.com`
    } else {
      shop = shop ?? new URL(app.hostOrigin)?.hostname
    }
  } catch (e) {}

  return shop
}

export const checkAccessHandle = () => {
  const appsAccess = process.env.REACT_APP_URL_ACCESS_HANDLE_SETTINGS?.split(",")?.map((domain) => domain?.trim())
  let currentShop = ""
  try {
    currentShop = getShopifyDomain()
  } catch (e) {}
  return appsAccess?.includes(currentShop)
}

export const setInfoUserClarity = (func, shop) => {
  try {
    if (process.env.REACT_APP_ENV === "production") {
      console.log(`${func} info user clarity`)
      window.clarity("identify", shop.shop)
      window.clarity("set", "shop_name", shop.shop)
      window.clarity("set", "plan_name", shop.plan_name)
      window.clarity("set", "shop_owner", shop.shop_owner)
      window.clarity("set", "email", shop.email)
    }
  } catch (e) {}
}

export const empty = (data) => {
  if (data === 0) {
    return true
  }
  if (typeof data === "number" || typeof data === "boolean") {
    return false
  }
  if (typeof data === "undefined" || data === null) {
    return true
  }
  if (typeof data.length !== "undefined") {
    return data.length === 0
  }
  let count = 0
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      count++
    }
  }

  return count === 0
}

export const currencyFormat = (currency, money, compact) => {
  const options = !empty(currency)
    ? {
        style: "currency",
        currency: currency ?? "USD"
      }
    : {}

  if (compact) {
    options.notation = "compact"
  }

  return new Intl.NumberFormat("en-US", {
    ...options
  }).format(money)
}

export const getFirstName = (name) => {
  if (empty(name)) {
    return ""
  }

  const arrName = name.split(" ")
  return arrName[0] ?? ""
}

export const convertCustomVariableToSave = (obj) => {
  const newObj = obj
  for (const key in newObj) {
    if (empty(newObj[key]) || newObj[key] === false) {
      newObj[key] = undefined
    }
    if (!isNaN(newObj[key]) && typeof newObj[key] !== "boolean") {
      newObj[key] = Number(newObj[key])
    }
    if (key === "bogos_settings_glider" && typeof newObj[key] === "string") {
      try {
        const parsed = JSON.parse(newObj[key])
        if (typeof parsed === "object" && parsed !== null) {
          newObj[key] = parsed
        } else {
        newObj[key] = undefined
        }
      } catch (e) {
        newObj[key] = undefined
      }
    }
  }
  return newObj
}
