// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
// import todo from '@src/views/apps/todo/store'
// import chat from '@src/views/apps/chat/store'
// import users from '@src/views/apps/user/store'
// import email from '@src/views/apps/email/store'
// import invoice from '@src/views/apps/invoice/store'
// import calendar from '@src/views/apps/calendar/store'
// import ecommerce from '@src/views/apps/ecommerce/store'
// import dataTables from '@src/views/tables/data-tables/store'
// import permissions from '@src/views/apps/roles-permissions/store'
import handle_settings from "@offers/handle-settings/store"
import analytics from "@offers/store/analytic/Analytic"
import appearance from "@offers/store/appearance/Appearance"
import custom_config from "@offers/store/config/Config"
import offers from "@offers/store/offer/Offer"
import others from "@offers/store/others/Others"
import pricing from "@offers/store/pricing/Pricing"
import settings from "@offers/store/setting/Settings"
import products from "@offers/store/variant/Variants"
import fake from "@offers/zfake_coupon/store"
import config_settings from "../views/Tools/ConfigSetings/configSettings.slice"
import authentication from "../views/Tools/Authentication/authentication.slice"
import notifications from "../views/Tools/Notification/notification.slice"
import recommendedApps from "../views/Tools/RecommendedApps/recommendedApp.slice"
import admins from "../views/Tools/ListAdmin/admin.slice"
import statistics from "../views/Tools/AbTesting/statisticShops.slice"
import banners from "../views/Tools/Banner/banner.slice"
import shopsBadReview from "../views/Tools/ShopsBadReview/shopsBadReview.slice"
import shopsManagement from "../views/Tools/ShopsManagement/shopsManagement.slice"
import shopsWhiteList from "../views/Tools/ShopWhiteList/shopsWhiteList.slice"
import shopsOneTimeCharge from "../views/Tools/OneTimeCharge/shopsOneTimeCharge.slice"
import convert_app from "../views/Tools/ConvertOldApp/slice"
import customVariable from "../views/Tools/CustomVariable/customVariable.slice.js"
import automation_test from "../views/Tools/AutomationTest/slice"
import block_list from "../views/Tools/BlockList/blockLists.slice.js"
import crisp_shifts from "../views/Tools/CrispShifts/crispShifts.slice.js"
import crisp_stats from "../views/Tools/CrispStat/crispStat.slice.js"
import crisp_users from "../views/Tools/CrispUser/crispUser.slice.js"
import offerChanges from "../views/Tools/ChangeLogSaveGiftOffer/offerChange.slice"
import settingChanges from "../views/Tools/ChangeLogSaveSetting/settingChange.slice"
import crisp_conversations from "../views/Tools/CrispConversation/crispConversation.slice"

const rootReducer = {
  auth,
  // todo,
  // chat,
  // email,
  // users,
  navbar,
  layout,
  // invoice,
  // calendar,
  // ecommerce,
  // dataTables,
  // permissions,
  offers,
  products,
  settings,
  appearance,
  fake,
  handle_settings,
  analytics,
  custom_config,
  pricing,
  others,
  config_settings,
  authentication,
  notifications,
  recommendedApps,
  admins,
  statistics,
  banners,
  shopsBadReview,
  shopsManagement,
  shopsWhiteList,
  shopsOneTimeCharge,
  convert_app,
  customVariable,
  automation_test,
  block_list,
  crisp_shifts,
  crisp_stats,
  crisp_users,
  offerChanges,
  settingChanges,
  crisp_conversations
}

export default rootReducer
